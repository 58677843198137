<template>
  <v-text-field
    :label="label"
    :value="value"
    @change="change"
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-menu :close-on-content-click="false" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon tile v-bind="attrs" v-on="on">
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
        </template>
        <v-color-picker
          mode="rgba"
          show-swatches
          :value="color"
          @input="input"
        ></v-color-picker>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value"],
  computed: {
    color() {
      let color = { r: 0, g: 0, b: 0, a: 0 };
      let value = this.value;
      if (value) {
        let match;
        match = value.match(/^rgba\(([0-9]+),([0-9]+),([0-9]+),([0-9.]+)\)$/);
        if (match) {
          color.r = parseInt(match[1]);
          color.g = parseInt(match[2]);
          color.b = parseInt(match[3]);
          color.a = parseFloat(match[4]);
        } else {
          match = value.match(/^rgb\(([0-9]+),([0-9]+),([0-9]+)\)$/);
          if (match) {
            color.r = parseInt(match[1]);
            color.g = parseInt(match[2]);
            color.b = parseInt(match[3]);
            color.a = 1;
          } else {
            match = value.match(/^#([0-9A-Fa-f]+)$/);
            if (match) {
              let hex = match[1].toUpperCase();
              if (hex.length == 3) {
                color.r = this.hex2int(`${hex[0]}${hex[0]}`);
                color.g = this.hex2int(`${hex[1]}${hex[1]}`);
                color.b = this.hex2int(`${hex[2]}${hex[2]}`);
                color.a = 1;
              } else if (hex.length == 4) {
                color.r = this.hex2int(`${hex[0]}${hex[0]}`);
                color.g = this.hex2int(`${hex[1]}${hex[1]}`);
                color.b = this.hex2int(`${hex[2]}${hex[2]}`);
                color.a = this.hex2int(`${hex[3]}${hex[3]}`);
              } else if (hex.length == 6) {
                color.r = this.hex2int(`${hex[0]}${hex[1]}`);
                color.g = this.hex2int(`${hex[2]}${hex[3]}`);
                color.b = this.hex2int(`${hex[4]}${hex[5]}`);
                color.a = 1;
              } else if (hex.length == 8) {
                color.r = this.hex2int(`${hex[0]}${hex[1]}`);
                color.g = this.hex2int(`${hex[2]}${hex[3]}`);
                color.b = this.hex2int(`${hex[4]}${hex[5]}`);
                color.a = this.hex2int(`${hex[6]}${hex[7]}`);
              }
            }
          }
        }
      }
      return color;
    },
  },
  methods: {
    hex2int(hex) {
      var len = hex.length,
        a = new Array(len),
        code;
      for (var i = 0; i < len; i++) {
        code = hex.charCodeAt(i);
        if (48 <= code && code < 58) {
          code -= 48;
        } else {
          code = (code & 0xdf) - 65 + 10;
        }
        a[i] = code;
      }
      return a.reduce(function (acc, c) {
        acc = 16 * acc + c;
        return acc;
      }, 0);
    },
    int2hex(num, width) {
      var hex = "0123456789ABCDEF";
      var s = "";
      while (num) {
        s = hex.charAt(num % 16) + s;
        num = Math.floor(num / 16);
      }
      if (typeof width === "undefined" || width <= s.length) {
        return s;
      }
      var delta = width - s.length;
      var padding = "";
      while (delta-- > 0) {
        padding += "0";
      }
      return padding + s;
    },
    change(color) {
      this.$emit("change", color);
    },
    input(color) {
      let value;
      if (color.a == 1) {
        value = "#";
        value += this.int2hex(color.r, 2);
        value += this.int2hex(color.g, 2);
        value += this.int2hex(color.b, 2);
      } else if (color.a == 0) {
        value = "transparent";
      } else {
        value = `rgba(${color.r},${color.g},${color.b},${color.a.toFixed(2)})`;
      }
      this.$emit("change", value);
    },
  },
};
</script>
